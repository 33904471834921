/*
 * @Author: Lijiahui
 * @Date: 2020-10-16 10:21:25
 * @LastEditors: Lijiahui
 * @LastEditTime: 2020-10-16 11:01:53
 */
import { createApp } from 'vue'
import App from '../App.vue'
const app = createApp(App);
import { createRouter, createWebHashHistory} from 'vue-router'
import baseRouter from './base.router'
import mainRouter from './main.router'
import filterRouter from './router.filter'

// Vue.use(VueRouter)
//
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function (location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }


const router = createRouter({
  // base: process.env.BASE_URL,
  history:createWebHashHistory(),
  // mode: 'history',
  routes:[
    ...baseRouter,
    ...mainRouter
  ]
})
filterRouter(router)
export default router
