/*
 * @Author: Lijiahui
 * @Date: 2020-10-16 10:47:25
 * @LastEditors: Lijiahui
 * @LastEditTime: 2020-10-18 22:40:02
 */
import Storage from '@utils/storage'
import isEmpty from 'lodash/isEmpty';
import Ajax from './Ajax';
import http from "@/commons/utils/ajax.js";


const getTimestamp = () => http.ajax({url:`/base/authoriza/login/getTimestamp?`+new Date().getTime()});
const userLogin =param => http.ajax({url:`base/authoriza/login/userLoginWeb`,data:param});

export default {
  getTimestamp,
  userLogin
}
