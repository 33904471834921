export default {
  //组件
  components: {
    expandDom: {
      functional: true,
      props: {
        row: Object,
        render: Function,
        index: Number,
        column: {
          type: Object,
          default: null
        }
      },
      render: (h, ctx) => {
      const params = {
        row: ctx.props.row,
        index: ctx.props.index
      };
if (ctx.props.column) params.column = ctx.props.column;
return ctx.props.render(h, params);
}
}
},
props: {
  tblRef: {
    type: String,
  default: "mytable"
  },
  cellStyle: {
    type: Function
  },
  border: {
    type: Boolean,
  default: false
  },
  rowDblclick: {
    // 单行双击事件
    type: Function,
  default: (row, event, column) => {
      console.log("default: " + row + "---" + event + "---" + column);
    }
  },
  operateWidth:{
    type: String,
  },
  tabheight: {
    type: String,
  default: "100%"
  },
  isLoad: {
    // 是否需要加载等待
    type: Boolean,
  default: true
  },
  isshow: {
    type: Boolean,
  default: false
  },
  showOpe: {
    type: Boolean,
  default: false
  },
  columns: {
    type: Array,
  default: []
  },
  operates: {},
  currentPage: {
  default() {
      return 1;
    },
    type: Number
  },
  pageSizes: {
  default() {
      return [ 30, 50, 100];
    },
    type: Array
  },
  pageSize: {
  default() {
      return 30;
    },
    type: Number
  },
  layout: {
  default() {
      return "total, sizes, prev, pager, next, jumper";
    },
    type: String
  },
  checkMultiple:{
    type: Boolean,
  default: false
  },
  url: {
    type: String,
  default: ""
  },
  param: {
    type: Object,
  default() {
      return {};
    }
  },
  urlHearder: {
    type: String,
  default: "application/x-www-form-urlencoded;charset=UTF8"
  },
  pagination: {
    type: Boolean,
  default: true
  }
},
data() {
  return {
    tableData: [],
    total: 0,
    currentRow: null,
    loading: false,
    page: {},
  };
},
// watch:{
//   param:{
//     handler(n,o){
//       console.log(n,'new')
//     },
//     deep:true,
//   }
// },
created() {
  // this.size = this.pageSize;
  // this.no = this.currentPage;
},
methods: {
  clickRow(row){/*行单击事件*/
    if(this.checkMultiple!=true){
      this.$refs[this.tblRef].clearSelection();
    }
    this.$refs[this.tblRef].toggleRowSelection(row)
  },
  // 点击分页
  handleSizeChange(val) {
    this.page.pageSize = val;
    this.$emit("update:pageSize", val);

    let size = 1;
    let toSize = Math.ceil(this.total/val);
    if(this.param.pageNo<=toSize){
      size=this.param.pageNo
    }else{
      size = toSize;
    }
    this.page.pageNo = size;
    this.$emit("update:currentPage", size);
    this.$emit("current-change", size);
    this.initTableData(this.page);
  },
  currentChange(val){
    this.currentRow = val;
  },
  handleCurrentChange(val) {
    this.page.pageNo = val;
    this.page.pageSize = this.param.pageSize;
    this.initTableData(this.page);
    this.$emit("update:currentPage", val);
    this.$emit("current-change", val);
  },
  selectionChange(data) {
    this.$emit("selection-change", data);
  },
  initTableData(page, callback) {

    this.$refs[this.tblRef].doLayout();
    if (this.isLoad) {
      this.loading = true;
    }
    if (page && page.hasOwnProperty("pageNo")) {
      this.param.pageNo = page.pageNo;
    } else {
      this.param.pageNo = this.currentPage;
    }
    if (page && page.hasOwnProperty("pageSize")) {
      this.param.pageSize = page.pageSize;
    } else {
      this.param.pageSize = this.pageSize;
    }
    var this_ = this;
    this.http
      .ajaxHandler()
      .success(function(code, msg, data) {
        if (code == 0) {
          if (this_.pagination) {
            this_.tableData = data.rows;
            this_.total = data.total;
          } else {
            if (data) {
              this_.tableData = data;
              this_.total = data.length;
            } else {
              this_.tableData = [];
              this_.total = 0;
            }
          }
          this_.$emit("getTotal", this_.total);
        } else {
          this_.tableData = [];
          this_.total = 0;
        }
        this_.page = {};
        if (callback) {
          callback();
        }
        this_.loading = false;
      })
      .error(function(code, msg) {
        this_.loading = false;
        this_.$message({
          message: msg,
          type: "warning"
        });
      })
      .post({
        headers: {
          "content-type": this.urlHearder
        },
        url: this.url,
        data: this.param
      });
  }
}
};
