<!--
 * @Author: Lijiahui
 * @Date: 2020-10-16 10:43:57
 * @LastEditors: Lijiahui
 * @LastEditTime: 2020-10-20 00:07:02
-->
<template>
  <div class="login_bck">
    <div class="login_content">
      <div class="title_1">欢迎登录</div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="账户密码" name="first">
          <tabsPsd ref="tabsPsd" />
        </el-tab-pane>
        <!--<el-tab-pane label="手机" name="second">
          <tabsPhone ref="tabsPhone" />
        </el-tab-pane>-->
      </el-tabs>
      <div class="regest">
        <!-- 立即注册 -->
        <!-- <span>还没有账号？<a class="target">立即注册</a></span> -->
      </div>
    </div>
    <div class="footer">
      <div>智慧物业管理</div>
      <div>
        备案号:京ICP备16017530号-1 版权商标所有“®”,请勿侵权 Copyright ©
        2012-2017. All Rights Reserved
      </div>
    </div>
  </div>
</template>

<script>
  import login from './js/login'
  export default {
    ...login
  }
</script>
<style lang='less' scoped>
  @import "./css/login.less";
</style>
