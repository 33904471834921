/*
 * @Author: Lijiahui
 * @Date: 2020-10-16 10:32:39
 * @LastEditors: Lijiahui
 * @LastEditTime: 2020-10-16 16:08:49
 */
import Storage from "@/commons/utils/storage.js"

// import Vue from "vue";
const mainRouter = [
  {
    path: '/',
    component: () => import('../views/map/map')
  },{
    path:'/index',
    component: () => import('../views/base/main/home/Home.vue'),
    children: [
      {
        path: '/',
        name:'desk',
        redirect:'/base/main/desk/desk'
      },{
        path: '/base/main/desk/desk',
      name:'desk',
        component: () => import('../views/base/main/desk/desk.vue')
      }
    ]
  },{
  path:'/base/main/forgetPsd/forgetPsd',
    name:'forgetPsd',
    component: () => import('../views/base/main/forgetPsd/forgetPsd.vue'),
},{
  path:'/processDesigner',
    name:'processDesigner',
    component: () => import('../views/process/processDesigner.vue'),
},{
  path:'/designer',
    name:'designer',
    component: () => import('../components/formDesigner.vue'),
},
{
    path: '/CesiumDemo',
    name:'CesiumDemo',
    component: () => import('../views/base/main/home/CesiumDemo.vue')
}
]
  let menu = Storage.get("menu");
  if(menu){
    menu = Storage.formatTreeData(menu,'0').children;
    mainRouter.forEach((val,index)=>{
      if(val.path=="/index"){
        let tmp=[];
        Storage.getDataRouter(menu,tmp);
        tmp = val.children.concat(tmp);
        val.tmp = val.children;
        val.children=tmp;
      }
    });
  }
export default mainRouter
